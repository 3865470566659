import React from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import Cta from "../elements/cta"
import { LocaleContext } from '../layout'
import BarTitle from '../../svg/bar_title.svg'
import { makeExcerpt } from '../../util'
import { traduireObject } from '../traduire'

const sectionProchainsConcerts = ({ data, prochainsConcerts }) => {
  const { locale } = React.useContext(LocaleContext)
  dayjs.locale(locale)

  const isDefaultLang = locale === 'fr'
  const isLienInterne = data.cta.lienInterne ? true : false
  let linkPageConcertsHref

  if (isLienInterne) {
    const linkSlug = data.cta.lienInterne.slug.current
    linkPageConcertsHref = isDefaultLang ? `/${linkSlug}` : `/${locale}/${linkSlug}`
  } else {
    linkPageConcertsHref = data.cta.lienExterne ? data.cta.lienExterne : null
  }

  const localeConcerts = traduireObject(prochainsConcerts, locale)

  return (
    <section className="section section-prochains-concerts">
      <div className="container">
        <header className="section-header">
          <h2 className="title is-2 section-title">
            <span className="section-title-text">{data.title}</span>
            <BarTitle className="section-header-bar"/>
          </h2>
        </header>
        <div className="concert-list columns is-multiline">
          {localeConcerts && localeConcerts.edges.map((concert) => (
            <div className="column is-4-desktop is-6-tablet" key={concert.node._id}>
              <article className="concert-box">
                <div className="concert-header">
                  {concert.node.date && (
                    <h5 className="title is-5 concert-date">{dayjs(concert.node.date).format('dddd D MMMM YYYY')}</h5>
                  )}
                </div>
                <div className="concert-content">
                  {concert.node.lieu && (
                    <h4 className="title is-4 concert-titre">{concert.node.lieu}</h4>
                  )}
                  {concert.node._rawDescription && (
                    <div className="concert-desc">
                      <p className="concert-excerpt">{ makeExcerpt( concert.node._rawDescription, 85) }</p>
                      {linkPageConcertsHref && (
                        <div className="concert-link">
                          <Link to={linkPageConcertsHref}>En savoir plus →</Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </article>
            </div>
          ))}
        </div>
        <Cta localeData={data.cta} />
      </div>
    </section>
  )
}

export default sectionProchainsConcerts
