import React from 'react'
import Cta from "../elements/cta"
import ImageHandler from '../imageHandler'
import Glide from '@glidejs/glide'
import {FaLongArrowAltRight, FaLongArrowAltLeft} from 'react-icons/fa'

class SectionTexteBouton extends React.Component {
  componentDidMount() {
    this.slider = new Glide('.glide', {
      type: 'slider',
    }).mount()
  }

  componentWillUnmount() {
    this.slider.destroy()
  }

  render() {
    return (
      <section className="section-galerie-photo">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {this.props.data.galerie.photos.map(photo => {
                return (
                  <li className="glide__slide" key={photo._key}>
                    <ImageHandler image={photo} />
                  </li>
                )})
              }
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<" aria-label="slide à gauche"><FaLongArrowAltLeft/></button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">" aria-label="slide à droite"><FaLongArrowAltRight/></button>
          </div>
        </div>
        <Cta localeData={this.props.data.cta} />
      </section>
    )
  }
}

export default SectionTexteBouton
