import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../layout'
import BlockContent from '../blockContent'
import { traduireObject } from '../traduire'

class SectionArchivesRepertoire extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categorie: "bNwaXS6LXTN4aWGIHrXZm0" // Solo
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      categorie: e.target.getAttribute('data-id')
    })
    this.setActive(e.target)
  }

  setActive(el) {
    const tabs = document.querySelectorAll('.tabs li')
    for (var i=0; i < tabs.length; i++) {
      tabs[i].classList.remove('is-active')
    }
    el.parentNode.classList.add('is-active')
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            titres: allSanityTitreRepertoire(sort: {fields: [auteurNom], order: ASC}) {
              edges {
                node {
                  _id
                  _type
                  _rawTitre
                  auteurPrenom
                  auteurNom
                  categorieRepertoire {
                    _type
                    title {
                      _type
                      fr
                      en
                    }
                    _rev
                  }
                }
              }
            }
            categories: allSanityCategorieRepertoire {
              edges {
                node {
                  _type
                  title {
                    _type
                    fr
                    en
                  }
                  _rev
                }
              }
            }
          }
        `}
        render={data => {
          const titresRepertoire = traduireObject(data.titres)
          const categoriesRepertoires = traduireObject(data.categories)
          return (
            <LocaleContext.Consumer>
              {({ locale }) => {
                return (
                  <section className="section section-archives is-medium">
                    <div className="container">
                      <header className="titreRepertoire-list-filtres">
                        <div className="tabs is-centered is-toggle is-toggle-rounded is-medium">
                          <ul>
                            {categoriesRepertoires && categoriesRepertoires.edges.map(cat => {
                              const liClass = this.state.categorie === cat.node._rev ? 'is-active' : null
                              return (
                                <li key={cat.node._rev} className={liClass}><a href='/' data-id={cat.node._rev} onClick={this.handleClick}>{cat.node.title}</a></li>
                              )
                            })}
                          </ul>
                        </div>
                      </header>
                      <table className="table is-striped is-fullwidth">
                        <thead>
                          <tr>
                            <th>Auteur</th>
                            <th>Titres</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>Auteur</th>
                            <th>Titres</th>
                          </tr>
                        </tfoot>
                        <tbody>
                        {titresRepertoire && titresRepertoire.edges.filter(titreRepertoire => {
                          return titreRepertoire.node.categorieRepertoire._rev === this.state.categorie
                        }).map((titreRepertoire) => (
                          <tr key={titreRepertoire.node._id}>
                            <td>
                              <p>
                                {titreRepertoire.node.auteurPrenom && (
                                  <span className="prenom">{titreRepertoire.node.auteurPrenom}</span>
                                )}
                                {titreRepertoire.node.auteurNom && (
                                  <span className="nom">{titreRepertoire.node.auteurNom}</span>
                                )}
                              </p>
                            </td>
                            <td>
                              {titreRepertoire.node._rawTitre && (
                                <BlockContent blocks={ titreRepertoire.node._rawTitre } />
                              )}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                )
              }}
            </LocaleContext.Consumer>
          )
        }}
      />
    )
}}

export default SectionArchivesRepertoire
