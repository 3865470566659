import React from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from '../layout'

const Cta = ({ localeData }) => {
  const classButton = localeData.typeDeLien === 'texte' ? 'is-text' : 'button is-rounded'
  const classCouleur = localeData.couleur === 'marron' ? 'is-primary' : localeData.couleur === 'rose' ? 'is-info' : 'is-dark'
  const buttonClassNames = `${classButton} ${classCouleur}`

  const { locale } = React.useContext(LocaleContext)
  const isDefaultLang = locale === 'fr'
  const isLienInterne = localeData.lienInterne ? true : false
  let linkHref

  if (isLienInterne) {
    const linkSlug = localeData.lienInterne.slug.current
    linkHref = isDefaultLang ? `/${linkSlug}` : `/${locale}/${linkSlug}`
  } else {
    linkHref = localeData.lienExterne
  }

  return (
    <div className={`cta ${classCouleur}`}>
      { localeData.typeDeLien === 'texte' && (
        <div className="cta-bar"></div>
      )}
      <Link
      to={linkHref}
      className={buttonClassNames}>
        { localeData.title } →
      </Link>
    </div>
  )
}

export default Cta
