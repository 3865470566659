import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import { LocaleContext } from '../layout'
import BlockContent from '../blockContent'
import { FaBackward, FaForward } from 'react-icons/fa'
import { traduireObject } from '../traduire'

class SectionArchivesConcerts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      year: dayjs().year()
    }

    this.handleBackwardClick = this.handleBackwardClick.bind(this)
    this.handleForwardClick = this.handleForwardClick.bind(this)
  }

  handleBackwardClick(e) {
    this.setState((state, props) => ({
      year: state.year - 1
    }))
  }

  handleForwardClick(e) {
    this.setState((state, props) => ({
      year: state.year + 1
    }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allSanityConcert(sort: {fields: [date], order: ASC}) {
              edges {
                node {
                  _id
                  date
                  lieu
                  _rawDescription
                }
              }
            }
          }
        `}
        render={data => {
          const concerts = traduireObject(data.allSanityConcert)
          const minYear = dayjs(concerts.edges[0].node.date).year()
          const maxYear = dayjs(concerts.edges[concerts.edges.length - 1].node.date).year()
          return (
            <LocaleContext.Consumer>
              {({ locale }) => {
                dayjs.locale(locale)

                return (
                  <section className="section section-archives is-medium">
                    <div className="container">
                      <header className="concert-list-filtres">
                        <div className="left">
                          {this.state.year > minYear && (<button className="button-filtre" onClick={this.handleBackwardClick}><FaBackward /></button>)}
                          {this.state.year > minYear && (<h3 className="title is-5" onClick={this.handleBackwardClick}>{this.state.year - 1}</h3>)}
                        </div>
                        <div className="center">
                          <h3 className="title is-3">{this.state.year}</h3>
                        </div>
                        <div className="right">
                          {this.state.year < maxYear && (<h3 className="title is-5" onClick={this.handleForwardClick}>{this.state.year + 1}</h3>)}
                          {this.state.year < maxYear && (<button className="button-filtre" onClick={this.handleForwardClick}><FaForward /></button>)}
                        </div>
                      </header>
                      <div className="concert-list columns is-multiline">
                        {concerts && concerts.edges.filter(concert => {
                          return dayjs(concert.node.date).year() === this.state.year
                        }).map((concert) => (
                          <div className="column is-4-desktop is-6-tablet" key={concert.node._id}>
                            <article className="concert-box">
                              <div className="concert-header">
                                {concert.node.date && (
                                  <h5 className="title is-5 concert-date">{dayjs(concert.node.date).format('dddd D MMMM YYYY')}</h5>
                                )}
                              </div>
                              <div className="concert-content">
                                {concert.node.lieu && (
                                  <h4 className="title is-4 concert-titre">{concert.node.lieu}</h4>
                                )}
                                {concert.node._rawDescription && (
                                  <div className="concert-desc">
                                    <div className="concert-excerpt">
                                      <BlockContent blocks={ concert.node._rawDescription } />
                                    </div>
                                    <div className="concert-link">
                                    </div>
                                  </div>
                                )}
                              </div>
                            </article>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                )
              }}
            </LocaleContext.Consumer>
          )
        }}
      />
    )
}}

export default SectionArchivesConcerts
