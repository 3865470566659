import React from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import Cta from "../elements/cta"
import { LocaleContext } from '../layout'
import BarTitle from '../../svg/bar_title.svg'
import { makeExcerpt } from '../../util'

const sectionPresse = ({ data, dernieresParutions }) => {
  const { locale } = React.useContext(LocaleContext)
  dayjs.locale(locale)

  const isDefaultLang = locale === 'fr'
  const isLienInterne = data.cta.lienInterne ? true : false
  let linkPageConcertsHref

  if (isLienInterne) {
    const linkSlug = data.cta.lienInterne.slug.current
    linkPageConcertsHref = isDefaultLang ? `/${linkSlug}` : `/${locale}/${linkSlug}`
  } else {
    linkPageConcertsHref = data.cta.lienExterne
  }

  return (
    <section className="section section-presse is-medium">
      <div className="container">
        <header className="section-header">
          <h2 className="title is-2 section-title">
            <span className="section-title-text">{data.title}</span>
            <BarTitle className="section-header-bar"/>
          </h2>
        </header>
        <div className="parution-list columns is-multiline">
          {dernieresParutions && dernieresParutions.edges.map((parution) => (
            <div className="column is-4-desktop is-6-tablet" key={parution.node._id}>
              <article className="parution-box">
                <div className="parution-header">
                  {parution.node.date && (
                    <h5 className="title is-5 parution-date">{dayjs(parution.node.date).format('dddd D MMMM YYYY')}</h5>
                  )}
                  {parution.node.media && (
                    <h5 className="title is-5 parution-media">{parution.node.media}</h5>
                  )}
                </div>
                <div className="parution-content">
                  {parution.node.titre && (
                    <h4 className="title is-4 parution-titre">{parution.node.titre[locale]}</h4>
                  )}
                  {parution.node._rawDescription && (
                    <div className="parution-desc">
                      <p className="parution-excerpt">{ makeExcerpt( parution.node._rawDescription, 120) }</p>
                      <div className="parution-link">
                        <Link to={linkPageConcertsHref}>En savoir plus →</Link>
                      </div>
                    </div>
                  )}
                </div>
              </article>
            </div>
          ))}
        </div>
        <Cta localeData={data.cta} />
      </div>
    </section>
  )
}

export default sectionPresse
