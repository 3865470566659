import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../layout'
import { traduireObject } from '../traduire'
import BlockContent from '../blockContent'
import Masonry from 'masonry-layout'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

class SectionArchivesPresse extends React.Component {
  componentDidMount() {
    const elem = document.querySelector('.grid');
    new Masonry( elem, {
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      percentPosition: true
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allSanityParution(sort: {fields: [date], order: DESC}) {
              edges {
                node {
                  _id
                  titre {
                    _type
                    fr
                    en
                  }
                  date
                  media
                  _rawDescription(resolveReferences: {maxDepth: 10})
                  auteur
                }
              }
            }
          }
        `}
        render={data => (
            <LocaleContext.Consumer>
              {(locale) => {
                const parutions = traduireObject(data.allSanityParution, locale)
                return (
                  <section className="section section-archives is-medium">
                    <div className="container">
                      <div className="parution-list columns grid is-multiline">
                        <div className="grid-sizer column is-4-desktop is-6-tablet"></div>
                        {parutions && parutions.edges.map((parution) => (
                          <div className="grid-item column is-4-desktop is-6-tablet" key={parution.node._id}>
                            <article className="parution-box">
                              <div className="parution-header">
                                {parution.node.date && (
                                  <h5 className="title is-5 parution-date">{dayjs(parution.node.date).format('dddd D MMMM YYYY')}</h5>
                                )}
                                {parution.node.media && (
                                  <h5 className="title is-5 parution-media">{parution.node.media}</h5>
                                )}
                              </div>
                              <div className="parution-content">
                                {parution.node.titre && (
                                  <h4 className="title is-4 parution-titre">{parution.node.titre}</h4>
                                )}
                                {parution.node._rawDescription && (
                                  <div className="parution-desc">
                                    {parution.node._rawDescription && (
                                      <BlockContent blocks={parution.node._rawDescription} />
                                    )}
                                    {parution.node.auteur && (
                                      <p><strong className="parution-auteur">{parution.node.auteur}</strong></p>
                                    )}
                                  </div>
                                )}
                              </div>
                            </article>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                )
              }}
            </LocaleContext.Consumer>
          )
        }
      />
    )
  }
}

export default SectionArchivesPresse
