import React from 'react'
import BlockContent from '../blockContent'

const SectionContenu = ({ data, rawData }) => {
  return (
    <section className="section section-contenu is-medium">
      <div className="container">
        <div className="columns">
          <div className="column is-7-desktop is-offset-4-desktop is-8-tablet is-offset-3-tablet">
            <article className="content is-medium">
              <BlockContent blocks={rawData.content } />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionContenu
