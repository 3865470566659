import React from 'react'
import ImageHandler from "../imageHandler"

const SectionImageHero = ({ data }) => {
  return (
    <section className="section-image-hero">
      <ImageHandler image={data.image} />
      <article className="citation">
        <h1 className="citation-text">{data.citation}</h1>
        <div className="citation-auteur">{data.auteur}</div>
      </article>
    </section>
  )
}

export default SectionImageHero
