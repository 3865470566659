import React from 'react'
import Figure from './elements/figure'
import { Link } from 'gatsby'
import { LocaleContext } from './layout'

const serializers = {
  types: {
    figure: Figure,
  },
  marks: {
    externalLink: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      ),
    internalLink: ({ children, mark }) => {
      const { locale } = React.useContext(LocaleContext)
      return (
        <Link to={'/'+mark.item.slug[locale].current}>{children}</Link>
      )
    }
  }
}

export default serializers
