import React from 'react'
import { FaPlay, FaPause } from 'react-icons/fa';
import { makeExcerpt } from '../../util'
import Plyr from 'plyr'

class AudioPlayer extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        active: null,
      }

      this.changeChannel = this.changeChannel.bind(this)
      this.getId = this.getId.bind(this)
      this.buildSource = this.buildSource.bind(this)
      this.setSource = this.setSource.bind(this)
      this.trackOnClick = this.trackOnClick.bind(this)
      this.playerOnEnded = this.playerOnEnded.bind(this)
      this.playerOnPlay = this.playerOnPlay.bind(this)
      this.playerOnPause = this.playerOnPause.bind(this)
    }

    changeChannel(e) {
      this.setSource( this.getId(e.target), this.buildSource(e.target), true )
    }

    getId(el) {
      return Number(el.getAttribute('data-id'))
    }

    buildSource(el) {
      var obj = [{
        src: el.getAttribute('data-audio'),
        type: el.getAttribute('data-type')
      }]

      return obj
    }

    setSource(selected, sourceAudio, play = null) {

      if(this.state.active !== selected) {
        this.setState({active: selected})
        this.player.source = {
          type: 'audio',
          title: 'test',
          sources: sourceAudio
        }

        for(var i = 0; i < this.songs.length; i++) {
          if(Number(this.songs[i].getAttribute('data-id')) === selected) {
            this.songs[i].classList.add('active')
          } else {
            this.songs[i].classList.remove('active')
          }
        }

        if(play) {
          this.player.play()
        }
      } else {
        this.player.togglePlay()
      }
    }

    trackOnClick(e) {
      this.setSource( this.getId(e.target.closest('.track')), this.buildSource(e.target.closest('.track')), true )
    }

    playerOnEnded(e) {
      const next = this.state.active + 1

      if(next < this.songs.length) {
        this.setSource( this.getId(this.songs[next]), this.buildSource(this.songs[next]), true )
      }
    }

    playerOnPlay(e) {
      for(var i = 0; i < this.songs.length; i++) {
        if(i === this.state.active) {
          this.songs[i].classList.add('is-played')
        } else {
          this.songs[i].classList.remove('is-played')
        }
      }
    }

    playerOnPause(e) {
      for(var i = 0; i < this.songs.length; i++) {
        this.songs[i].classList.remove('is-played')
      }
    }
    
    componentDidMount() {
      if (typeof document !== `undefined`) {
        this.player = new Plyr('#audioPlayer', {
          controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
          settings: [],
        });

        if (typeof this.player !== `undefined`) {

          const playlist = document.querySelector('.playlist')
          this.songs = playlist.querySelectorAll('.track')

          this.setSource( this.getId(this.songs[0]), this.buildSource(this.songs[0]), false )

          document.querySelectorAll('.track').forEach(function(el) {
            if (typeof el !== `undefined` && typeof el.addEventListener === `function`) {
              el.addEventListener('click', this.trackOnClick)
            }
          }, this)

          this.player.on('ended', this.playerOnEnded)
          this.player.on('play', this.playerOnPlay)
          this.player.on('pause', this.playerOnPause)

        }
      }
    }

    componentWillUnmount() {
      if (typeof document !== `undefined` && typeof this.player !== `undefined`) {
        this.player.off('ended', this.playerOnEnded)
        this.player.off('play', this.playerOnPlay)
        this.player.off('pause', this.playerOnPause)
        this.player.destroy()
      
        document.querySelectorAll('.track').forEach(function(el) {
          if (typeof el !== `undefined` && typeof el.removeEventListener === `function`) {
            el.removeEventListener('click', this.trackOnClick)
          }
        }, this)
      }
    }

    render() {
      return (
        <div className="audioPlayer-wrapper">
          <div className="playlist">
            {this.props.tracks && this.props.tracks.map((track, index) => {
              const url = track.track ? track.track.asset.url : track.videoUrl ? track.videoUrl : null
              const mimeType = track.track ? track.track.asset.mimeType : "mp3"
              return (
                <article className="track" key={index + 1} data-id={index} data-audio={url} data-type={mimeType}>
                  <div className="columns is-mobile">
                    <div className="column is-narrow">
                      <div className="play">
                        <FaPlay/>
                      </div>
                      <div className="pause">
                        <FaPause/>
                      </div>
                    </div>
                    <div className="column">
                      {track.titre && (
                        <h5 className="title is-5">{index + 1}. {track.titre}</h5>
                      )}
                      {track._rawDescription && (
                        <h6 className="subtitle is-6">{makeExcerpt(track._rawDescription, 35)}</h6>
                      )}
                    </div>
                  </div>
                </article>
              )
            })}
          </div>
          <audio controls id="audioPlayer"></audio>
        </div>
      )
    }
}

export default AudioPlayer
