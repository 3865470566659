import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../layout'
import { traduireObject } from '../traduire'
import Gallery from 'react-grid-gallery'

class SectionArchivesGaleriesPhotos extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allSanityGaleriePhoto(filter: {displayFront: {eq: true}}) {
              edges {
                node {
                  _type
                  _id
                  title {
                    _type
                    fr
                    en
                  }
                  photos {
                    mini: asset {
                      fixed(width: 200, height: 200) {
                        ...GatsbySanityImageFixed
                        width
                        height
                      }
                    }
                    full: asset {
                      fluid(maxWidth: 1500) {
                        ...GatsbySanityImageFluid
                      }
                    }
                    alt {
                      _type
                      fr
                      en
                    }
                    photographe {
                      _type
                      nom
                      site
                      copyright
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
            <LocaleContext.Consumer>
              {( { locale } ) => {
                const galeriesPhotos = traduireObject(data.allSanityGaleriePhoto, locale)
                return (
                  <section className="section section-archives is-medium">
                    <div className="container">
                      <div className="galeriePhoto-list">
                        {galeriesPhotos && galeriesPhotos.edges.map((galeriePhoto) => {
                          const images = galeriePhoto.node.photos.map((img) => {
                            return {
                              src: img.full.fluid.src,
                              srcSet: img.full.fluid.srcSet,
                              thumbnail: img.mini.fixed.src,
                              thumbnailWidth: 200,
                              thumbnailHeight: 200,
                              nano: img.full.fluid.base64,
                              caption: `Photo: ${img.photographe.nom}`
                            }
                          })
                          return (
                          <div className="galeriePhoto" key={galeriePhoto.node._id}>
                            {galeriePhoto.node.title && (
                              <h4 className="title is-4">{galeriePhoto.node.title}</h4>
                            )}
                            <div className="is-clearfix">
                              <Gallery images={images} id={galeriePhoto.node._id} enableImageSelection={false} backdropClosesModal={true} imageCountSeparator={locale === 'fr' ? ' sur ' : ' of '} rowHeight={200}/>
                            </div>
                          </div>
                        )})}                        
                      </div>
                    </div>
                  </section>
                )
              }}
            </LocaleContext.Consumer>
          )
        }
      />
    )
  }
}

export default SectionArchivesGaleriesPhotos
