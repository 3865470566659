import React from 'react'
import BarTitle from '../../svg/bar_title.svg'
import Cta from "../elements/cta"
import AudioPlayer from '../elements/audioPlayer'

const wrapper = (children) => (
  <section className="section is-medium">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          {children}
        </div>
      </div>
    </div>
  </section>
)

const SectionGalerieAudio = (props) => {
  return props.isStandelone ? 
    wrapper(
      <GalerieAudio {...props} />
    ) :
    <GalerieAudio {...props} />
}

const GalerieAudio = ({ data }) => (
  <div className="section-galerie-audio">
    <header className="section-header">
      <h2 className="title is-2 section-title">
        <span className="section-title-text">{data.title}</span>
        <BarTitle className="section-header-bar"/>
      </h2>
    </header>
    <AudioPlayer tracks={data.tracks} />
    <Cta localeData={data.cta} />
  </div>
)

export default SectionGalerieAudio
