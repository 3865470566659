import React from 'react'
import ImageHandler from "../imageHandler";
import ReactPlayer from 'react-player'

const SectionVideo = ({data}) => {
  return (
    <section className="section-video is-medium">
      {data.image && <ImageHandler image={data.image}/>}
      <div className="video-wrapper">
        <ReactPlayer url={data.video} controls={true} />
      </div>
    </section>
  )
}

export default SectionVideo
