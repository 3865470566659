import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AudioPlayer from '../elements/audioPlayer'
import { traduireObject } from '../traduire'

const SectionArchivesAudio = ({ data, rawdata,  }) => {
  const tracks = traduireObject(useStaticQuery(graphql`
    {
      allSanityPisteAudio {
        edges {
          node {
            _type
            track {
              asset {
                _id
                _type
                _rev
                assetId
                url
                _key
                label
                originalFilename
                sha1hash
                extension
                mimeType
                size
                path
                id
              } 
            }
            titre {
              _type
              fr
              en
            }
            _rawDescription(resolveReferences: {maxDepth: 10})
            videoUrl
          }
        }
      }
    }
  `))
  const tracksCondensed = tracks.allSanityPisteAudio.edges.reduce((acc, curr) => {
    acc.push(curr.node)
    return acc
  }, [])
  return (
    <section className="section section-archives is-medium">
      <div className="container">
        <div className="section-galerie-audio">
          <div className="columns is-centered">
            <div className="column is-8">
              <AudioPlayer tracks={tracksCondensed} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionArchivesAudio
