import React from 'react'
import { matchSectionWithRawSection } from "../../util"
import SectionImageHero from "./sectionImageHero"
import SectionTexteBouton from "./sectionTexteBouton"
import SectionDerniersAlbums from "./sectionDerniersAlbums"
import SectionGalerieAudio from "./sectionGalerieAudio"
import ErrorBoundary from '../elements/errorBoundary'

const SectionDouble = ({ data, rawData }) => {
  const sections = matchSectionWithRawSection(data.sections, rawData.sections)
  return (
    <section className="section section-double is-medium">
      <div className="container">
        <div className="columns">
          {
            sections.map(section => {
              switch (section._type) {
                case 'sectionImageHero':
                  return (
                    <div className="column" key={section._key}>
                      <SectionImageHero data={section.data} isStandelone={false} />
                    </div>
                  )

                case 'sectionTexteBouton':
                  return (
                    <div className="column" key={section._key}>
                      <SectionTexteBouton data={section.data} rawData={section.rawData} isStandelone={false} />
                    </div>
                  )

                case 'sectionDerniersAlbums':
                  return (
                    <div className="column" key={section._key}>
                      <SectionDerniersAlbums data={section.data} rawData={section.rawData} isStandelone={false} />
                    </div>
                  )

                case 'sectionGalerieAudio':
                  return (
                    <div className="column is-offset-1 is-4" key={section._key}>
                      <ErrorBoundary>
                        <SectionGalerieAudio data={section.data} rawData={section.rawData} isStandelone={false} />
                      </ErrorBoundary>
                    </div>
                  )

                default:
                  return null
              }
            })
          }
        </div>
      </div>
    </section>
  )
}

export default SectionDouble
