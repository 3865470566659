import React from 'react'
import Cta from "../elements/cta"
import BlockText from '../blockText'

const SectionTexteBouton = ({ data, rawData }) => {
  return (
    <section className="section section-texte-bouton is-medium">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 has-text-centered">
            <article className="content is-medium">
              <BlockText blocks={rawData.contenu } />
              <Cta localeData={data.cta} />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTexteBouton
