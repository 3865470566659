import React from "react"
import { graphql } from "gatsby"
import traduire from "../components/traduire"
import SectionImageHero from "../components/sections/sectionImageHero"
import SectionTexteBouton from "../components/sections/sectionTexteBouton"
import SectionProchainsConcerts from "../components/sections/sectionProchainsConcerts"
import SectionDouble from "../components/sections/sectionDouble"
import SectionGaleriePhoto from "../components/sections/sectionGaleriePhoto"
import SectionPresse from "../components/sections/sectionPresse"
import SectionContenu from "../components/sections/sectionContenu"
import SectionArchivesConcerts from "../components/sections/sectionArchivesConcerts"
import SectionArchivesPresse from "../components/sections/sectionArchivesPresse"
import SectionArchivesDiscographie from "../components/sections/sectionArchivesDiscographie"
import SectionArchivesAudio from "../components/sections/sectionArchivesAudio"
import SectionArchivesGaleriesPhotos from "../components/sections/sectionArchivesGaleriesPhotos"
import SectionArchivesRepertoire from "../components/sections/sectionArchivesRepertoire"
import SectionContact from "../components/sections/sectionContact"
import { matchSectionWithRawSection } from "../util"
import BarTitle from '../svg/bar_title.svg'
import ErrorBoundary from '../components/elements/errorBoundary'

export const query = graphql`
  query(
    $nombreConcerts: Int,
    $today: Date,
    $nombreParutions: Int
  ) {
    ProchainsConcerts: allSanityConcert(limit: $nombreConcerts, filter: {date: {gte: $today}}, sort: {fields: [date], order: ASC}) {
      edges {
        node {
          _id
          date
          lieu
          _rawDescription
        }
      }
    }
    DernieresParutions: allSanityParution(limit: $nombreParutions, sort: {fields: [_createdAt], order: ASC}) {
      edges {
        node {
          _createdAt
          _id
          titre {
            fr
            en
          }
          date
          media
          _rawDescription
          auteur
        }
      }
    }
  }
`

const Page = (props) => {
  const localeData = props.localeData
  const sections = matchSectionWithRawSection(localeData.sections, localeData._rawSections)
  const columnClass = 'column is-12 has-text-centered'
  const slug = props.pageContext ? props.pageContext.slug : ""
  let pageClassName
  if (slug) {
    slug.fr.current === '/' ? 
      pageClassName = `is-page-home` :
      pageClassName = `is-page-${slug.fr.current}`
  } else {
    pageClassName = `is-page-404`
  }

  return (
    <div className={pageClassName}>
      {
        props.location.pathname !== '/' && props.location.pathname !== '/en' && props.location.pathname !== '/en/' && (
          <div>
            <header className="section page-header">
              <div className="container">
                <div className="columns">
                  <div className={columnClass}>
                    <div className="section-header">
                      <h1 className="title is-1 section-title">
                        <span className="section-title-text">{localeData.title}</span>
                        <BarTitle className="section-header-bar"/>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        )
      }
      {
        sections.map(section => {
          switch (section._type) {
            case 'sectionImageHero':
              return (
                <SectionImageHero key={section._key} data={section.data} />
              )

            case 'sectionTexteBouton':
              return (
                <SectionTexteBouton key={section._key} data={section.data} rawData={section.rawData} />
              )

            case 'sectionProchainsConcerts':
              const prochainsConcerts = props.data.ProchainsConcerts
              return (
                <SectionProchainsConcerts key={section._key} data={section.data} prochainsConcerts={prochainsConcerts}/>
              )

            case 'sectionDouble':
              return (
                <SectionDouble key={section._key} data={section.data} rawData={section.rawData} />
              )

            case 'sectionGaleriePhoto':
              return (
                <SectionGaleriePhoto key={section._key} data={section.data} rawData={section.rawData} />
              )

            case 'sectionPresse':
            const dernieresParutions = props.data.DernieresParutions
              return (
                <SectionPresse key={section._key} data={section.data} rawData={section.rawData} dernieresParutions={dernieresParutions} />
              )

            case 'sectionContenu':
              return (
                <SectionContenu key={section._key} data={section.data} rawData={section.rawData} />
              )

            case 'sectionArchives':
              const archiveType = section.data.typeArchives

              switch (archiveType) {
                case 'concerts':
                  return (
                    <SectionArchivesConcerts key={section._key} data={section.data} rawData={section.rawData} />
                  )
                
                case 'presse':
                  return (
                    <SectionArchivesPresse key={section._key} data={section.data} rawData={section.rawData} />
                  )

                case 'discographie':                 
                  return (
                    <SectionArchivesDiscographie key={section._key} data={section.data} rawData={section.rawData} />
                  )

                case 'audio':
                  return (
                    <ErrorBoundary key={section._key}>
                      <SectionArchivesAudio key={section._key} data={section.data} rawData={section.rawData} />
                    </ErrorBoundary>
                  )

                case 'photos':
                  return (
                    <SectionArchivesGaleriesPhotos key={section._key} data={section.data} rawData={section.rawData}/>
                  )

                case 'repertoire':
                  return (
                    <SectionArchivesRepertoire key={section._key} data={section.data} rawData={section.rawData}/>
                  )

                default:
                  return null

              }

            case 'sectionContact':
              return (
                <ErrorBoundary key={section._key}>
                  <SectionContact key={section._key} data={section.data} rawData={section.rawData} />
                </ErrorBoundary>
              )

            default:
              return null
          }
        })
      }
    </div>
  )
}

export default traduire(Page)
