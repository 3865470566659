import React from 'react'
import ContactForm from '../elements/contactForm'
import Message from '../elements/message'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class sectionContact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      success: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.hideNotifications = this.hideNotifications.bind(this)
  }

  handleSuccess() {
    this.setState({success: true})
    this.refs.contactform.reset()

    const hideNotifications = this.hideNotifications

    setTimeout(function() {
      hideNotifications()
    }, 10000)
  }

  hideNotifications() {
    this.setState({success: false})
  }

  handleSubmit = (e, data) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data })
    })
      .then(() => this.handleSuccess())
      .catch(error => console.log(error))

    e.preventDefault()
  }

  render() {
    return (
      <section className="section is-medium section-contact-form">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9">
              <div className="notifications">
                {this.state.success &&
                  <Message
                      type="success"
                      text="Merci pour votre message. Bonne journée !"
                      hide={this.hideNotifications}
                      />
                }
              </div>
              <div className="block has-text-centered">
                <h4 className="subtitle is-4">{this.props.data.intro}</h4>
              </div>
              <ContactForm handleSubmit={this.handleSubmit} labels={this.props.rawData} ref="contactform"/>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default sectionContact
