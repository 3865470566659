import React from 'react'
import BarTitle from '../../svg/bar_title.svg'
import ImageHandler from '../imageHandler'
import BlockContent from '../blockContent'
import Cta from '../elements/cta'

import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

const wrapper = (children) => (
  <section className="section is-medium">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          {children}
        </div>
      </div>
    </div>
  </section>
)

const SectionDerniersAlbums = (props) => {
  return props.isStandelone ? 
    wrapper(
      <DerniersAlbums {...props} />
    ) :
    <DerniersAlbums {...props} />
}

const DerniersAlbums = ({ data, rawData, isStandelone = true }) => (
  <div className="section-derniers-albums">
    <header className="section-header">
      <h2 className="title is-2 section-title">
        <span className="section-title-text">{data.title}</span>
        <BarTitle className="section-header-bar"/>
      </h2>
    </header>
    <div className="columns">
      <div className="column is-4">
        <ImageHandler image={data.album.image} />
      </div>
      <div className="column is-8">
        {data.album.titre && (
          <h4 className="title is-4">{data.album.titre}</h4>
        )}
        {data.album.date && (
          <h6 className="subtitle is-6">Sortie le {dayjs(data.album.date).format('dddd D MMMM YYYY')}</h6>
        )}
        {data.album._rawDescription && (
          <BlockContent blocks={data.album._rawDescription} />
        )}
      </div>
    </div>
    <Cta localeData={data.cta} />
  </div>
)

export default SectionDerniersAlbums
