import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BlockContent from '../blockContent'
import ImageHandler from '../imageHandler'
import { traduireObject } from '../traduire'
import { LocaleContext } from '../layout'

const SectionArchivesDiscographie = ({ data, rawdata }) => {
  const queryData = useStaticQuery(graphql`
    {
      ImageChoc: file(relativePath: {eq: "choc.jpg"}) {
        asset: childImageSharp {
          fixed(width: 50, height: 50, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ImageR9: file(relativePath: {eq: "label_R9.jpg"}) {
        asset: childImageSharp {
          fixed(width: 50, height: 50, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ImageDiapason: file(relativePath: {eq: "diapasondor.jpg"}) {
        asset: childImageSharp {
          fixed(width: 50, height: 50, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      albums: allSanityAlbum(sort: {fields: [order], order: ASC})  {
        edges {
          node {
            _id
            titre
            image {
              alt {
                _type
                fr
                en
              }
              asset {
                fluid(maxWidth: 1200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            _rawDescription(resolveReferences: {maxDepth: 10})
            order
            choc
            diapason
            r9
            _rawCritiques(resolveReferences: {maxDepth: 10})
          }
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)

  const albums = traduireObject(queryData.albums, locale)
  const recompenses = {
    diapason: queryData.ImageDiapason,
    choc: queryData.ImageChoc,
    r9: queryData.ImageR9
  }
  return (
    <section className="section section-archives is-medium">
      <div className="container">
        <div className="album-list columns is-mobile is-multiline is-centered">
          {albums && albums.edges.map((album) => (
            <div className="column is-9-tablet is-12-mobile album" key={album.node._id}>
              <div className="columns is-multiline is-mobile">
                <div className="column is-3-tablet is-4-mobile couverture">
                  <ImageHandler image={album.node.image} />
                </div>
                <div className="column is-9-tablet is-8-mobile description">
                {(album.node.choc !== null || album.node.diapason !== null || album.node.r9 !== null) && (
                  <div className="recompense">
                    {album.node.choc && (<ImageHandler image={recompenses.choc}/>)}
                    {album.node.diapason && (<ImageHandler image={recompenses.diapason}/>)}
                    {album.node.r9  && (<ImageHandler image={recompenses.r9}/>)}
                  </div>
                  )}
                  {album.node.titre && (
                    <h4 className="title is-4">{album.node.titre}</h4>
                  )}
                  {album.node._rawDescription && (
                    <BlockContent blocks={album.node._rawDescription} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionArchivesDiscographie
