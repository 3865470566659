import React from "react"
import Proptypes from "prop-types"
import { createLocaleTextGetter } from "../util"

function traduireObject(object, locale) {
  return createLocaleTextGetter( locale )( object )
}

function traduire(Component) {
  return class Traduire extends React.Component {
    static propTypes = {
      localeData: Proptypes.object,
      pageContext: Proptypes.shape({
        locale: Proptypes.string,
        page: Proptypes.object,
        slug: Proptypes.object,
      }),
    }
    constructor(props) {
      super(props)

      this.getLocalizedContent = createLocaleTextGetter(
        this.props.pageContext.locale
      )
    }

    render() {
      return (
        <Component
          {...this.props}
          localeData={this.getLocalizedContent(this.props.pageContext.page)}
        />
      )
    }
  }
}

export { traduire as default, traduireObject }
